<template>
  <div class="screeningIntroduction">
    <van-button class="fh" type="info" @click="$router.back(-1)">返回</van-button>
    <h3>视力筛查报告说明</h3>
    <div class="nr">
      <h4>裸眼视力：</h4>
      <p>不戴框架眼镜、隐形眼镜时测得视力</p>

      <h4>屈光球镜S：</h4>
      <p>表示近视或者远视，“-”表示近视，负号数字是近视度数；“+”表示远视，正号数字是远视度数。</p>

      <h4>屈光柱镜C：</h4>
      <p>表示散光，近视散光用负号表示，远视散光用正号表示。</p>

      <h4>轴位A：</h4>
      <p>表示散光的方向</p>
      <p> 一般小学阶段的孩子，裸眼视力在1.0（5.0）及以上的就不会有太大问题</p>

      <h4>判断小朋友的视力是否正常，可以看以下两个指标：</h4>
      <p>1、孩子视力是否达到同年龄段孩子正常视力的下限。3岁视力一般可达4.7以上，4岁视力1、一般可达4.8以上，5岁及以上视力一般可达4.9以上，一般视力发育就没问题。</p>
      <p>2、孩子的双眼视力是否均衡。一般两眼视力在视力表上不要相差两行及以上。</p>
      <img src="@/assets/screening/img/slzc.jpg" alt="">
      <p>一般情况下新生儿的眼球较小，眼轴较短，此时双眼处于远视状态，大多为生理性远视，是一种“远视储备”，可理解为“对抗”发展为近视的“缓冲区”。</p>
      <p>远视储备量不足指裸眼视力正常，但与同龄孩子相比，远视度数低于相应年龄段生理屈光度范围。说明小孩快要朝近视方向发展，家长应开始重视。</p>

      <h4>眼生物测量：</h4>
      <p>眼球生物测量采用光学生物测量仪器，可以检测到孩子的眼轴增长情况，了解视力发展速度、角膜曲率以及角膜的厚度等重要数据。</p>
      <p>眼轴通常随年龄增长逐步增大，新生儿的眼轴长度只有17mm左右，从出生到3岁这段时间变化是最快的，也是远视度数降低最快的，之后生长速度会逐渐减慢，成年人的正常值约为24mm。</p>
      <img src="@/assets/screening/img/ysw.jpg" alt="">
      <p>当眼轴超过26.5mm时，患上高度近视眼底病变的风险会大大增加。所以记录眼轴发育情况可以密切关注儿童近视情况的发展。</p>


      <h3 style="padding-top: 20px;">近视防控第一步：建立屈光发育档案</h3>
      <h4 style="padding-top: 0;">屈光发育档案并不是普通的视力检查，在儿童成长的过程中，建立屈光发育档案可以动态监测视力，它包含了身高、角膜曲率、眼轴、眼压、散瞳验光等一系列综合视光检查。</h4>
      <p>屈光发育档案更多的是一种预防监控系统，不仅仅是对已发生近视的儿童才建立的，而是对所有适龄儿童(3-17岁）都有作用。</p>
      <p>3岁开始，孩子的认知能力开始完善，双眼视已经形成，眼镜各功能也开始完善，我们就需要在视力和屈光检查基础上，同时检查孩子的视功能，了解这个时期孩子的眼位、调节、集合功能，融合能力和立体视等等，做到最有效的近视预防。通过每3-6个月更新一次屈光发育档案的方式对近视早做预防。</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "screeningIntroduction"
}
</script>

<style lang="scss" scoped>
  .screeningIntroduction {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  h3 {
    padding: 10px 0;
    text-align: center;
  }
  .nr {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  h4 {padding-top: 20px;padding-bottom: 5px;}
  img {
    display: block;
    width: 90%;
    margin: 10px auto;
  }
  .fh {
    position: absolute;
    left: 0;
    top: 0;
  }
</style>